<template>
  <div id="records">
    <BaseBanner v-bind="bannerProps"></BaseBanner>
    <main class="content" :style="{ 'background-image': `url(${background.mainBg})` }">
      <section class="inner-content" :style="{ 'background-image': `url(${background.contentBg})` }">
        <DiscoveryList :discoveries="discoveries" />
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from '@vue/composition-api'
import BaseBanner, { bannerProps } from '@/components/game/shared/baseBanner'
import { getters } from '@/components/game/store'
import { HOST_URL, API_V4 } from '@/config/api';
import DiscoveryList from './components/DiscoveryList.vue';

export default defineComponent({
  components: {
    BaseBanner,
    DiscoveryList
  },
  props: ['memberLineId', 'memberName', 'groupId', 'updateGroupStoryShip', 'updateFinishedDemo'],
  data() {
    return {
      bannerProps: {
        ...bannerProps
      },
      staticImages: {
        banner: require('@/assets/images/banner.png'),
        avatar: require('@/assets/images/banner-avatar.png'),
        icon: require('@/assets/images/yama/yama-icon.png'),
        background: require('@/assets/images/pg-bg.png'),
        contentBg: require('@/assets/images/content-bg.png')
      },
      background: {
        mainBg: "",
        contentBg: ""
      },
      discoveries: [],
      lineId: ""
    }
  },
  watch: {
    memberLineId: {
      handler(lineId) {
        if (lineId) {
          this.lineId = lineId;
          this.getRecords();
        }
      },
      immediate: true
    }
  },
  created() {
    this.initViewData();
  },
  methods: {
    initViewData() {
      const topicInfo = getters.getTopicInfo('record');
      if (topicInfo) {
        this.bannerProps.imgSrc = topicInfo.banner;
        this.bannerProps.content = this.sampleContent(topicInfo.banner_content);
        this.bannerProps.banner_avatar = topicInfo.banner_avatar;
        this.bannerProps.styles = "top: 70px; left: 155px;";
        this.background.mainBg = topicInfo.background;
        this.background.contentBg = topicInfo.question_block || this.staticImages.contentBg;
      } else {
        // 如果 store 中沒有 records 相關資料，使用靜態圖片
        this.bannerProps.imgSrc = this.staticImages.banner;
        this.bannerProps.content = '聖誕快樂';
        this.bannerProps.banner_avatar = this.staticImages.avatar;
        this.bannerProps.styles = "top: 70px; left: 155px;";
        this.background.mainBg = this.staticImages.background;
        this.background.contentBg = this.staticImages.contentBg;
      }
    },
    sampleContent(contents) {
      if (!contents || !contents.length) return '聖誕快樂';
      const randomIndex = Math.floor(Math.random() * contents.length);
      return contents[randomIndex];
    },
    async getRecords() {
      try {
        const response = await axios.get(`${HOST_URL}${API_V4}/groups/${this.groupId || this.memberLineId}/discoveries`);
        if (response.data && response.data.discoveries) {
          this.discoveries = response.data.discoveries;
        }
      } catch (error) {
        console.log(`遊戲服務中斷: ${error.message}`);
        this.discoveries = [];
      }
    }
  }
});
</script>

<style lang="scss" scoped>
#records {
  main.content {
    min-height: calc(100vh - 150px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    
    section.inner-content {
      width: 100%;
      max-width: 400px;
      min-height: 400px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      padding: 8px 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
