import axios from 'axios';
import { getters, mutations } from "@/components/game/store";
import GameNotification from "@/components/game/shared/notification";
import BaseBanner, { bannerProps }from "@/components/game/shared/baseBanner";
import eventBus from "@/components/game/store/eventBus";
import Vue from 'vue';
import { HOST_URL, API_V2, API_V3 } from '@/config/api';

export default {
  components: {
    BaseBanner,
    GameNotification
  },
  props: ['memberLineId', 'memberName', 'groupId', 'updateGroupStoryShip', 'updateFinishedDemo'],
  data() {
    return {
      judge: this.$route.params.judge,
      positions: "",
      selectPositoin: {},
      progress: [],
      hadFoundExhibitIds: [],
      hadFoundRelatedIds: [],
      hadFoundTimelineIds: [],
      hadFoundPositionIds: [],
      translateItemToHadFound: {
        preExhId: 'hadFoundExhibitIds',
        preRelId: 'hadFoundRelatedIds',
        preTimeId: 'hadFoundTimelineIds',
        prePosId: 'hadFoundPositionIds'
      },
      lastContent: '重新對話',
      tutorialMode: false,
      currentTutorialStep: 'focusPositionMap',
      tutorialSteps: ['focusPositionMap', 'focusFirstPosition', 'showFirstPositionSouls',
                      'showFirstPositionFirstOption', 'showFirstPositionSecondOption',
                      'finished'],
      story_id: "",
      selectConversation: {},
      icon: {
        user: ""
      },
      background: {
        mapBg: "",
        mainBg: ""
      },
      bannerProps: {...bannerProps}
    };
  },
  async created(){
    this.initViewData()
    await axios.get(`${HOST_URL}${API_V2}/groups/${this.groupId || this.memberLineId}/positions?line_id=${this.memberLineId}`)
    .then(response => {
      this.init(response.data)
    }).catch(error=> {
      console.log(error)
      console.log(`遊戲服務中斷: ${error.message}`)
    })
  },
  mounted() {
    eventBus.$on('received', (itemType, item) => {
      this.updateReceivedHadFoundItem(itemType, item);
    });
  },
  beforeDestroy() {
    eventBus.$off('received')
  },
  methods: {
    initViewData () {
      const topicInfo = getters.getTopicInfo('position')
      this.bannerProps.imgSrc = topicInfo.banner
      this.bannerProps.content = this.sampleContent(topicInfo.banner_content)
      this.bannerProps.styles = "top: 50px; left: 155px;"
      this.bannerProps.banner_avatar = topicInfo.banner_avatar
      this.background.mapBg = topicInfo.map
      this.background.mainBg = topicInfo.background
      Vue.set(this.icon, 'default_location', topicInfo.default_location)
      Vue.set(this.icon, 'default_location_active', topicInfo.default_location_active)
      Vue.set(this.icon, 'locked_location', topicInfo.locked_location)
      Vue.set(this.icon, 'locked_location_active', topicInfo.locked_location_active)
      Vue.set(this.icon, 'conversation_avatar', topicInfo.conversation_avatar)
      Vue.set(this.icon, 'conversation_background', topicInfo.conversation_background)
      Vue.set(this.icon, 'conversation_select_1', topicInfo.conversation_select_1)
      Vue.set(this.icon, 'conversation_select_2', topicInfo.conversation_select_2)
      Vue.set(this.icon, 'conversation_select_3', topicInfo.conversation_select_3)
      Vue.set(this.icon, 'conversation_repeat', topicInfo.conversation_repeat)
    },
    getLocationIcon (position) {
      const isLock = !this.isUnlocked(position)
      const isActive = position.tag === this.selectPositoin.tag
      if (isLock && isActive) return this.icon.locked_location_active
      if (isLock) return this.icon.locked_location
      if (isActive) return this.icon.default_location_active

      return this.icon.default_location
    },
    sampleContent(contents){
      return contents[Math.floor(Math.random() * contents.length)]
    },
    init(data){
      this.positions = data.positions;
      this.progress = data.progress;
      this.hadFoundExhibitIds = data.hadFound.exhibitIds;
      this.hadFoundRelatedIds = data.hadFound.relatedIds;
      this.hadFoundPositionIds = data.hadFound.positionIds;
      this.hadFoundTimelineIds = data.hadFound.timelineIds;
      this.currentTutorialStep = (data.member.task.currentTutorialStep ? data.member.task.currentTutorialStep : 'focusPositionMap');
      this.tutorialMode = (data.story.tutorialMode && this.currentTutorialStep !== 'finished');
      if (data.demo) { this.updateFinishedDemo(); }
    },
    isUnlocked(position) {
      // FIXME: 檢查這段，一直被多次執行
      if (!position.id) { return true; }

      const isUnlocked = Object.keys(this.translateItemToHadFound).every(lockItem => {
        if (position[lockItem].length === 0) { return true; }

        return position[lockItem].every(lock => {
          return this[this.translateItemToHadFound[lockItem]].includes(lock);
        })
      })
      return isUnlocked;
    },
    changeLine(content){
      if (!content) { return; }

      return content.replaceAll('\n', '<br>');
    },
    nextTutorialStep() {
      const currentTutorialStepIndex = this.tutorialSteps.indexOf(this.currentTutorialStep);
      this.currentTutorialStep = this.tutorialSteps[currentTutorialStepIndex + 1];
      axios.put(`${HOST_URL}${API_V3}/tutorial_tasks/判官頁面流程操作`,
                { line_id: this.memberLineId, group_token: this.groupId || this.memberLineId, page: 'souls',
                  progress: this.currentTutorialStep });
    },
    updatedFoundList(newItem) {
      if (this.progress.includes(newItem.id)) { return; }

      this.progress.push(newItem.id)
    },
    updateReceivedHadFoundItem(itemType, item) {
      if (!item) { return; }

      this[itemType].push(item.id);
    },
    setSelectPosition(tag){
      this.selectPositoin = this.positions.find(position => position.tag === tag);
      this.selectConversation = this.selectPositoin.conversations[0];
      if (this.selectPositoin.name === '大學社團辦公室') {
        this.nextTutorialStep();
      }
    },
    setSelectConversation(con){
      this.selectConversation = this.selectPositoin.conversations.find( conversation => conversation.pre_con == con );
      if (this.selectPositoin.name === '大學社團辦公室') {
        this.nextTutorialStep();
      }
    },
    resetSelectConversation(){
      if (!this.hadFoundPositionIds.includes(this.selectPositoin.id)) {
        this.updateGroupStoryShip('position', this.selectPositoin.name);
      }
      this.selectConversation = this.selectPositoin.conversations[0]
    },
    onLoaded () {
      mutations.closeLoading()
    }
  }
};