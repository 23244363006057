<template>
  <div :class="{profile_light:isLight,profile_dark:isDark}">
    <!-- 讀取頁面 -->
    <div v-if="loading" class="loading-page">
      <div class="loading-spinner">
        <img src="~$images/profile2/loadingpic2.gif" alt="Loading..." />
      </div>
    </div>

    <!-- 主要內容 -->
    <div v-else>
      <div class="banner">
        <img src="~$images/team-banner@3x.png" />
      </div>

      <main v-if="line_id != ''" class="content">
        <div class="profile">
        <!--新版-->
          <div class="profile-basic" @click="isDark=!isDark,isLight=!isLight">
            <!--頭像-->
            <div class="profile-basic-left">
              <div id="member-icon-block">
                <span id="logo">
                  <img
                    :src="picture"
                    width="75px"
                    height="75px"
                    style="border-radius: 50%;"
                  />
                </span>
              </div>
            </div>
            <!-- 第一行名字 -->             
            <div class="profile-basic-right">
              <div class="profile-name">{{ name }}</div>
              <span class="no">判官編號</span>
              <span class="number profile-style">{{ number }}</span>
              <!-- 第二行報到日期 -->
              <div></div>
              <span class="item-time">報到日期</span>
              <span class="profile-style">{{convertyear()}}{{ month }}{{ day }}{{hour}}</span>
              <div class="profile-part">
                <span class="item-part">所屬部門</span>
                <span class="profile-style">尚未開放</span>
                <span class="item-age">歲數</span>
                <span class="profile-style">{{new Date().getFullYear()-this.year}}</span><!--計算判官登入年數-->
              </div>
            </div>
          </div>
        <!--升階獎勵-->
          <div class="exp">
            <div class="star1">
            <img src="~$images/profile2/Star.png" width="20px"/>
            </div>
            <span class="profile-level profile-title">判官階級Lv.{{level()}}</span><!--階級後方還會依照等級顯示Lv.？-->
            <span class="profile-level">【{{ titlename() }}】</span><!--顯示判官等級名稱-->
            <div class="exp-bar">{{expshow()}}/{{expneed()}}</div>
          </div>
        <!--查案紀錄-->
          <div class="file-records">
            <div class="star1">
              <img src="~$images/profile2/Star.png" width="20px"/>
            </div>
            <div class="data-record profile-title">查案紀錄</div>
            <!--顯示已破案件/全部案件-->
            <div class="file-record2">
              <div class="data">破獲案件:</div>
              <div class="margin">
                <p class="file">{{fins_story}}/{{totalstory}}</p>
              </div>
            </div>
          <!--顯示已找到證人/全部證人-->
            <div class="file-record3">
              <div class="data">找到證人:</div>
              <div class="margin">
                <p class="file">{{fins_related_num}}/{{totalrelatednum}}</p>
              </div>
            </div>
          <!--顯示已找到證物/全部證物-->
            <div class="file-record4">
              <div class="data">尋得證物:</div>
              <div class="margin">
                <p class="file">{{fins_exhibits_num}}/{{totalexhitnum}}</p>
              </div>
            </div>
            <div class="storage">
              <a href="https://liff.line.me/1653951718-Zr0DWB7z">
                <img src="~$images/profile2/Arhive.png"/>
              </a>
            </div>
            <div class="storagetitle">收藏庫</div>
            <div class="achievement">
              <a href="https://liff.line.me/1653951718-5b4qNxBM">
                <img src="~$images/profile2/achievement.png"/>
              </a>
            </div>
            <div class="achievementtitle">案件選單</div>
          </div>
          <div class="profile-theme">
            <div class="profile-theme__title">
              <div class="star1">
                <img src="~$images/profile2/Star.png" width="20px"/>
              </div>
              <div class="topic-title profile-title"><b>主題造型</b></div>
            </div>
            <div class="profile-theme__items">
              <button
                class="theme__card"
                v-for="(topic, index) in topics"
                :key="index"
                :class="{ active: topic.selected, 'not-own': !topic.own }"
                v-on:click='clickTheme(topic.id)'
              >
                <div class="theme__card-icon">
                    <img
                      :src="topic.display_image"
                      :alt="topic.name"
                      draggable="false"
                      width="60px"
                      height="124px"
                    />
                </div>
                <div class="theme__card-title">
                    <p>{{topic.name}}</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="special-task">
          <div class="hidden-task">隱藏成就<img src="~$images/profile2/padlock.png" width="16"></div>
          <hr class="hr"/>
          <div v-if="finish_timeline_task_ratio <= 0.5" class="hs-fail"><!--尚未達成條件-->
            <div class="task-title">
              <div class="star">
                <img src="~$images/profile2/G-Star.png" width="20px"/>
              </div>
              <div class="task-fail">《幽倩的轉生》</div>
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule-f">通關案件「時間的女兒」以及「惡有惡報」</div>
            <div class="task-rule-fail">尚未完成</div>
          </div>
          <div v-else class="hs-sucess"><!--成功達成條件-->
            <div class="task-title">
              <div class="star">
                <img src="~$images/profile2/Star.png" width="20px"/>
              </div>
              <div class="task-sucess">《幽倩的轉生》</div>  
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule">通關案件「時間的女兒」以及「惡有惡報」</div>
            <div class="task-rule-sucess"><a :href="'https://backend.judicial-intern.club/timeline_task?line_id=' + line_id ">點擊查看</a></div><!--需撰寫條件-->
          </div>
          <hr class="hr"/>
          <div v-if="!finish_story_no44" class="hs-fail"><!--尚未達成條件-->
            <div class="task-title">
              <div class="star">
                <img src="~$images/profile2/G-Star.png" width="20px"/>
              </div>
              <div class="task-fail">《最後的萬聖節》</div>
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule-f">通關案件「來不及長大」</div>
            <div class="task-rule-fail">尚未完成</div>
          </div>
          <div v-else class="hs-sucess"><!--成功達成條件-->
            <div class="task-title">
              <div class="star">
              <img src="~$images/profile2/Star.png" width="20px"/>
              </div>
              <div class="task-sucess">《最後的萬聖節》</div>  
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule">通關案件「來不及長大」</div>
            <div class="task-rule-sucess"><a :href="'https://backend.judicial-intern.club/trick_or_treat?line_id=' + line_id ">點擊查看</a></div><!--需撰寫條件-->  
          </div>
          <hr class="hr"/>
          <div v-if="!finish_story_no115" class="hs-fail"><!--尚未達成條件-->
            <div class="task-title">
              <div class="star">
                <img src="~$images/profile2/G-Star.png" width="20px"/>
              </div>
              <div class="task-fail">《萬聖節後日談》</div>
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule-f">通關案件「萬聖節的奇蹟」</div>
            <div class="task-rule-fail">尚未完成</div>
          </div>
          <div v-else class="hs-sucess"><!--成功達成條件-->
            <div class="task-title">
              <div class="star">
                <img src="~$images/profile2/Star.png" width="20px"/>
              </div>
              <div class="task-sucess">《萬聖節後日談》</div>  
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule">通關案件「萬聖節的奇蹟」</div>
            <div class="task-rule-sucess"><a :href="'https://backend.judicial-intern.club/pages/Afterhalloween?line_id=' + line_id ">點擊查看</a></div><!--需撰寫條件-->
          </div>
          <hr class="hr"/>
          <div v-if="!topics[5].own" class="hs-fail"><!--尚未達成條件-->
            <div class="task-title">
              <div class="star">
                <img src="~$images/profile2/G-Star.png" width="20px"/>
              </div>
              <div class="task-fail">《員工旅遊就是要親近大自然》</div>
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule-f">擁有露營主題</div>
            <div class="task-rule-fail">尚未完成</div>
          </div>
          <div v-else class="hs-sucess"><!--成功達成條件-->
            <div class="task-title">
              <div class="star">
              <img src="~$images/profile2/Star.png" width="20px"/>
              </div>
              <div class="task-sucess">《員工旅遊就是要親近大自然》</div>  
            </div>
            <div class="rule">解鎖條件</div>
            <div class="task-rule">擁有露營主題</div>
            <div class="task-rule-sucess"><a href="https://backend.judicial-intern.club/pages/Camping">點擊查看</a></div><!--需撰寫條件-->
          </div>
        </div>
      </main>

      <main v-else>
        <div style="text-align: center; font-size: 24px; margin-top: 20px;">
          資料載入中
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      // judge: this.$route.params.judge,
      content: this.sampleContnet(),
      loading: true,  // 新增的loading狀態
      line_id: '',
      name: "",
      number: "",
      picture: "",
      hour: "",
      year:"",
      month: "",
      day: "",
      fins_exhibits_num: 0,
      fins_related_num: 0,
      totalrelatedum: 0,
      totalexhibitnum: 0,
      topics: [],
      finish_story_no44: false,
      finish_story_no115: false,
      isLight:true,
      isDark:false,
      lv:[1,2,3,4,5,6,7,8,9,10,11,12],
      title:[
        '實習判官','牛頭馬面','黑白無常','金枷銀鎖','日夜遊神','文武判官','陰陽司佐','城隍境主','無毒鬼王','寶藏天女','目連尊者','地藏菩薩'
      ],
      expoint:[
        0,1000,2000,3000,4500,6000,8000,10000,13000,16000,20000,24000
      ],
      exptoup:[
        1000,1000,1000,1500,1500,2000,2000,3000,3000,4000,4000
      ],
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('reset')) {
      if (urlParams.get('group_id')) {
        localStorage.setItem('groupId', urlParams.get('group_id'));
      } else {
        localStorage.removeItem('groupId');
      }
      window.history.pushState(null, null, window.location.pathname);
    }
    try {
      await window.liff.init({ liffId: '1653951718-rxQYeq3a' })
                  .catch((err) => { alert(err) })
      if (!window.liff.isLoggedIn()) {
        alert("尚未登入");
        return window.liff.login();
      }
      const context = await window.liff.getContext();
      console.log(context.userId);
      await this.getProfileData(context.userId);
      this.loading = false;
    } catch(err) {
      alert(err)
    }
    
    
    // this.getProfileData('U016222f6eeee6bc349074ce00e46aa37')
    
    // if (localStorage.lineId) {
    //   this.getProfileData(localStorage.lineId);
    // } else {
    //   window.liff.getProfile().then(profile => {
    //     localStorage.lineId = profile.userId;
    //     this.getProfileData(profile.userId);
    //   }).catch((err) => {
    //     console.log(`error ${err}`)
    //   });
    // }
  },
  methods: {
    exp() {
     return this.fins_exhibits_num * 5 + this.fins_related_num * 5;
    },
    titlename(){
      if (this.exp()>this.expoint[10])
         { return this.title[10] }
      else if (this.exp()>this.expoint[9])
         { return this.title[9] }
      else if (this.exp()>this.expoint[8])
         { return this.title[8] }
      else if (this.exp()>this.expoint[7])
         { return this.title[7] }
      else if (this.exp()>this.expoint[6])
         { return this.title[6] }
      else if (this.exp()>this.expoint[5])
         { return this.title[5] }
      else if (this.exp()>this.expoint[4])
         { return this.title[4] }
      else if (this.exp()>this.expoint[3])
         { return this.title[3] }
      else if (this.exp()>this.expoint[2])
         { return this.title[2] }
      else if (this.exp()>this.expoint[1])
         { return this.title[1] }
      else if (this.exp()>this.expoint[0])
         { return this.title[0] }
    },
    level(){
      if (this.exp()>this.expoint[10])
         {return this.lv[10]}
      else if (this.exp()>this.expoint[9])
         {return this.lv[9]}
      else if (this.exp()>this.expoint[8])
         {return this.lv[8]}
      else if (this.exp()>this.expoint[7])
         {return this.lv[7]}
      else if (this.exp()>this.expoint[6])
         {return this.lv[6]}
      else if (this.exp()>this.expoint[5])
         {return this.lv[5]}
      else if (this.exp()>this.expoint[4])
         {return this.lv[4]}
      else if (this.exp()>this.expoint[3])
         {return this.lv[3]}
      else if (this.exp()>this.expoint[2])
         {return this.lv[2]}
      else if (this.exp()>this.expoint[1])
         {return this.lv[1]}
      else if (this.exp()>this.expoint[0])
         {return this.lv[0]}
    },
    expneed(){
      if (this.exp()>this.expoint[10])
         {return this.exptoup[10]}
      else if (this.exp()>this.expoint[9])
         {return this.lv[9]}
      else if (this.exp()>this.expoint[8])
         {return this.exptoup[8]}
      else if (this.exp()>this.expoint[7])
         {return this.exptoup[7]}
      else if (this.exp()>this.expoint[6])
         {return this.exptoup[6]}
      else if (this.exp()>this.expoint[5])
         {return this.exptoup[5]}
      else if (this.exp()>this.expoint[4])
         {return this.exptoup[4]}
      else if (this.exp()>this.expoint[3])
         {return this.exptoup[3]}
      else if (this.exp()>this.expoint[2])
         {return this.exptoup[2]}
      else if (this.exp()>this.expoint[1])
         {return this.exptoup[1]}
      else if (this.exp()>this.expoint[0])
         {return this.exptoup[0]}
    },
    expshow(){
      if (this.exp()>this.expoint[10])
         {return this.exp()-this.expoint[10]}
      else if (this.exp()>this.expoint[9])
         {return this.exp()-this.expoint[9]}
      else if (this.exp()>this.expoint[8])
         {return this.exp()-this.expoint[8]}
      else if (this.exp()>this.expoint[7])
         {return this.exp()-this.expoint[7]}
      else if (this.exp()>this.expoint[6])
         {return this.exp()-this.expoint[6]}
      else if (this.exp()>this.expoint[5])
         {return this.exp()-this.expoint[5]}
      else if (this.exp()>this.expoint[4])
         {return this.exp()-this.expoint[4]}
      else if (this.exp()>this.expoint[3])
         {return this.exp()-this.expoint[3]}
      else if (this.exp()>this.expoint[2])
         {return this.exp()-this.expoint[2]}
      else if (this.exp()>this.expoint[1])
         {return this.exp()-this.expoint[1]}
      else if (this.exp()>this.expoint[0])
         {return this.exp()}
    },
    convertyear() {
      if (this.year == 2020) { return '庚子'; }
      else if (this.year == 2021) { return '辛丑'; }
      else if (this.year == 2022) { return '壬寅'; }
      else if (this.year == 2023) { return '癸卯'; }
      else if (this.year == 2024) { return '甲辰'; }
      else if (this.year == 2025) { return '乙巳'; }
      else if (this.year == 2026) { return '丙午'; }
      else if (this.year == 2027) { return '丁未'; }  
      else { return '詢問小閻王'; }
    },

    sampleContnet() {
      let contents = ["吼..........", "吼..........吼..........", "....(蹭))"];
      return contents[Math.floor(Math.random() * 3)];
    },
    async getProfileData(lineId){
      await axios.get("https://backend.judicial-intern.club/api/v1/line/profile",{
        params: { line_id: lineId }
      })
      .then(response => {
        this.init(response.data)
        console.log('response', response)
      }).catch(error=> {
        console.log(error)
      })
      await axios.get(`https://backend.judicial-intern.club/api/v2/members/${lineId}/topics`)
           .then(response => { this.topics = response.data.topics })
    },
    init(data) {
      this.line_id = data.member.id;
      this.name = data.member.display_name;
      this.number = data.member.number
      this.hour = data.member.created_hour
      this.month = data.member.created_month
      this.year = data.member.created_year
      this.picture = data.member.picture_url
      this.day = data.member.created_day
      this.fins_exhibits_num = data.group.fins_exhibits_num
      this.fins_related_num = data.member.findRelatedNum
      this.finish_timeline_task_ratio = data.group.finish_timeline_task_ratio
      this.finish_story_no44 = data.group.finish_story_no44
      this.finish_story_no115 = data.group.finish_story_no115
      this.totalrelatednum = data.member.allRelatedNum
      this.totalstory = data.member.allStoryNum
      this.totalexhitnum = data.member.allExhibitNum
      this.fins_story = data.member.finishStoryNum
    },
    changeLine(content) {
      var temp_content = "";
      if (content) {
        content.split("\n").forEach(function(e) {
          temp_content = temp_content + e + "<br>";
        });
        return temp_content;
      }
    },
    // convertJudgeName(judge_key) {
    //   if (judge_key === 'exhibit') { return '魅嵐'; }
    //   else if (judge_key === 'related') { return '冥鏡'; }
    //   else if (judge_key === 'soul') { return '靈陽'; }
    //   else if (judge_key === 'timeline') { return '幽倩'; }
    //   else if (judge_key === 'hint') { return '小石獅'; }
    //   else { return '小閻王'; }
    // },
    clickTheme(topic_id){
      let index = this.topics.findIndex(topic => topic.own && !topic.selected && topic_id === topic.id)
      if (index !== -1) {
        axios.put(`https://backend.judicial-intern.club/api/v2/members/${this.line_id}/topics/${topic_id}`)
             .then(response => {
               if (response.data.success) {
                 this.topics = this.topics.map(topic => {
                   if (topic.id === topic_id) { topic.selected = true
                   } else {
                     topic.selected = false
                   }
                   return topic
                 })
               }
             })
      }
    },
    renderedImageToChatbot(card_url) {
      axios.get("https://backend.judicial-intern.club/api/v1/line/image?image_url="+ card_url + "&line_id=" + this.line_id)
      window.liff.closeWindow();
    },
  },
};
</script>

<style lang="scss" src="./style-2.scss" scoped></style>
