import axios from 'axios';
import Search from "../../shared/search.vue";
import { getters, mutations } from "@/components/game/store";
import GameNotification from "@/components/game/shared/notification";
import DetailList from './tools/detailList';
import Collapse from '@/components/common/Collapse';
import List from "./tools/list.vue";
import BaseBanner, { bannerProps } from "@/components/game/shared/baseBanner";
import Vue from 'vue';
import eventBus from "@/components/game/store/eventBus";
import { HOST_URL, API_V2, API_V3 } from '@/config/api';

export default {
  components: {
    Search,
    GameNotification,
    List,
    DetailList,
    Collapse,
    BaseBanner
  },
  props: ['memberLineId', 'memberName', 'groupId', 'updateGroupStoryShip', 'updateFinishedDemo'],
  data() {
    return {
      relateds: [],
      victim: {},
      selectRelated: {},
      searchRelatedText: "",
      newRelated: false,
      found: true,
      tutorialMode: false,
      currentTutorialStep: 'focusFirstRelated',
      tutorialSteps: ['focusFirstRelated', 'showFirstRelatedTestimonies', 'focusVictimName',
                      'focusSecondRelatedName', 'searchSecondRelatedName', 'clickSecondRelated',
                      'showSecondRelatedTestimonies', 'finished'],
      hadFoundExhibitIds: [],
      hadFoundRelatedIds: [],
      hadFoundTimelineIds: [],
      hadFoundPositionIds: [],
      lineId: "",
      progress: [],
      sendSearchForm: false,
      hasInitDone: false,
      bannerProps: {...bannerProps},
      icon: {},
      background: {
        mainBg: ""
      }
    };
  },
  watch: {
    memberLineId: {
      async handler(lineId) {
        const hasParams = lineId && this.groupId;
        if (!hasParams) { return; }

        try {
          await axios.get(`${HOST_URL}${API_V2}/groups/${this.groupId || this.memberLineId}/relateds?line_id=${this.memberLineId}`)
            .then(async response => {
              await this.init(response.data);
              this.$nextTick(() => {
                this.hasInitDone = true
              });
            });
        } catch (error) {
          console.log(error)
          console.log(`遊戲服務中斷: ${error.message}`)
        }
      },
      immediate: true
    },
    allInitDone: {
      handler (isDone) {
        isDone ? mutations.closeLoading() : mutations.openLoading()
      },
      immediate: true
    }
  },
  computed: {
    allInitDone() {
      return this.hasInitDone
    }
  },
  created() {
    this.initViewData();
  },
  mounted() {
    eventBus.$on('received', (itemType, item) => {
      this.updateReceivedHadFoundItem(itemType, item);
    });
  },
  beforeDestroy () {
    eventBus.$off('received')
  },
  methods: {
    initViewData() {
      const topicInfo = getters.getTopicInfo('related')
      this.bannerProps.imgSrc = topicInfo.banner
      this.bannerProps.content = this.sampleContent(topicInfo.banner_content)
      this.bannerProps.styles = "top: 70px; left: 155px;"
      this.bannerProps.banner_avatar = topicInfo.banner_avatar
      this.background.mainBg = topicInfo.background
      Vue.set(this.icon, 'type', topicInfo.type)  //'default'/'collapse'
      Vue.set(this.icon, 'witness_active', topicInfo.witness_active)
      Vue.set(this.icon, 'witness_found', topicInfo.witness_found)
      Vue.set(this.icon, 'witness', topicInfo.witness)
      Vue.set(this.icon, 'question_close_icon', topicInfo.question_close_icon)
      Vue.set(this.icon, 'question_open_icon', topicInfo.question_open_icon)
      Vue.set(this.icon, 'locked_testimony', topicInfo.locked_testimony)
    },
    init(data) {
      this.victim = data.relateds.filter((related) => related.status === 'victim');
      this.relateds = data.relateds.filter((related) => related.status !== 'victim');
      // FIXME: Remove progress.
      this.progress = data.progress;
      this.hadFoundExhibitIds = data.hadFound.exhibitIds;
      this.hadFoundRelatedIds = data.hadFound.relatedIds;
      this.hadFoundPositionIds = data.hadFound.positionIds;
      this.hadFoundTimelineIds = data.hadFound.timelineIds;
      this.currentTutorialStep = (data.member.task.currentTutorialStep ? data.member.task.currentTutorialStep : 'focusFirstRelated');
      this.tutorialMode = (data.story.tutorialMode && this.currentTutorialStep !== 'finished');
      this.relateds = this.relateds.map((related) => {
        related.found = (related.status === 'default' || data.progress.includes(related.id))
        return related;
      });
      this.selectRelated = this.relateds.find(related => related.found) || {};
      if (data.demo) { this.updateFinishedDemo(); }
    },
    searchRelated(queryText) {
      if (this.sendSearchForm) { return; }

      this.sendSearchForm = true
      let targetRelated = this.relateds.find(related => related?.name === queryText);
      if (targetRelated?.name === '周百樂') { this.nextTutorialStep(); }
      if (this.progress.includes(targetRelated?.id) || targetRelated?.status === 'default') {
        this.selectRelated = targetRelated
        this.sendSearchForm = false
        return;
      }
      this.updateGroupStoryShip('related', queryText);
      this.sendSearchForm = false
      if (!targetRelated) { return; }

      this.updateRelated(targetRelated);
      this.selectRelated = targetRelated;
    },
    nextTutorialStep() {
      const currentTutorialStepIndex = this.tutorialSteps.indexOf(this.currentTutorialStep);
      this.currentTutorialStep = this.tutorialSteps[currentTutorialStepIndex + 1];
      axios.put(`${HOST_URL}${API_V3}/tutorial_tasks/判官頁面流程操作`,
        { line_id: this.memberLineId, group_token: this.groupId || this.memberLineId, page: 'relateds',
          progress: this.currentTutorialStep });
    },
    updateRelated(newItem) {
      if (this.progress.includes(newItem.id)) { return; }

      this.progress.push(newItem.id);
      this.relateds = this.relateds.map((related) => {
        if (related.id !== newItem.id) { return related; }

        related.found = true
        return related
      })
    },
    sampleContent(contents) {
      return contents[Math.floor(Math.random() * contents.length)];
    },
    changeLine(content) {
      if (!content) { return; }

      return content.replaceAll('\n', '<br>');
    },
    changeRelated(index) {
      this.found = true;
      this.selectRelated = this.relateds[index];
      this.searchRelatedText = this.selectRelated.name;
    },
    updateReceivedHadFoundItem(itemType, item) {
      if (!item) { return; }

      this[itemType].push(item.id);
      if (itemType === 'hadFoundRelatedIds') {
        this.updateRelated(item);
      }
    }
  }
};
