import axios from "axios";
import { HOST_URL, API_V2 } from '@/config/api';

const $axios = axios.create({
  baseURL: HOST_URL
})

export const YamaAPI = {
  fetchGameInfo ({group_id = '', line_id = ''}) {
    const params = { group_id , line_id }
    return $axios.get(`${API_V2}/answers`, { params })
  },
  fetchStartGame ({group_id = ''}) {
    const params = { group_id }
    return $axios.post(`${API_V2}/answers`, null, { params })
  },
  postAnswer ({line_id, question_id, content, group_id }) {
    const params = { question_id, content, group_id }
    return $axios.put(`${API_V2}/member_answers/${line_id}`, null, { params })
  },
  finishAnswer ({line_id, group_id}) {
    const params = { finish: true, group_id }
    return $axios.put(`${API_V2}/member_answers/${line_id}`, null, { params })
  },
  useTool ({line_id, group_id}) {
    const params = { group_id }
    return $axios.get(`${API_V2}/tool/${line_id}`, { params })
  }
}



export default {
  setProfile ({payload}) {
    return $axios.post("/profile", payload)
  },
  getmemberInfo (lineID) {
    return $axios.get(`${API_V2}/member_infos/${lineID}`)
  },
  // getLineProfileByYama (payload) {
  //   return $axios.get("/api/v1/line/relateds", payload)
  // },
  /**
   * 主題 API
   * @param {*} line_id 
   * @returns 主題資訊
   */
  getTopicInfo (line_id) {
    return axios.get(`${HOST_URL}${API_V2}/members/${line_id}`)
  }
}
