<template>
  <div>
    <h4>留存率 : {{ retention_ratio }}</h4>
     <div>
      <line-chart v-if="compareNewStoryOpen != ''" :line-data="compareNewStoryOpen" :options="options"/>
    </div>
    <div v-for="(storyOpen, key) in storiesOpen" :key="key">
      <bar-chart v-if="storyOpen != ''" :bar-data="storyOpen"/>
    </div>
    <div>
      <bar-chart v-if="solveRate != ''" :bar-data="solveRate"/>
    </div>
    <div>
      <bar-chart v-if="solveAverage != ''" :bar-data="solveAverage"/>
    </div>
    <div>
      <bar-chart v-if="sell != ''" :bar-data="sell"/>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/reports/line-chart.vue';
import BarChart from '@/components/reports/bar-chart.vue';
import axios from 'axios';
import { HOST_URL, API_V1 } from '@/config/api';

export default {
  components: {
    LineChart,
    BarChart
  },
  mounted() {
    // axios.get(`${HOST_URL}${API_V1}/reports/story/story_join`)
    // .then(response => {
    //   this.setNewStoryOpen(response.data)
    // }).catch(error=> {
    //   console.log(error)
    // })

    // axios.get(`${HOST_URL}${API_V1}/reports/story/compare_story_join`)
    // .then(response => {
    //   this.setCompareNewStoryOpen(response.data)
    // }).catch(error=> {
    //   console.log(error)
    // })

    ['day', 'week', 'month'].forEach(period => {
      console.log(period)
      axios.get(`${HOST_URL}${API_V1}/reports/story/open?period=${period}`)
           .then(response => {
             const data = response.data;
             this.storiesOpen[period] = {
               labels: data.labels,
               datasets: [
                 {
                   label: data.data_name,
                   data: data.datasets,
                   pointBackgroundColor: 'white',
                   borderWidth: 1,
                   pointBorderColor: '#249EBF',
                   backgroundColor: '#f87979',
                 }
               ]
             }
      }).catch(error=> console.log(error))
    })
    

    axios.get(`${HOST_URL}${API_V1}/reports/story/story_solve_rate`)
    .then(response => {
      this.setSolveRate(response.data)
    }).catch(error=> {
      console.log(error)
    })

    axios.get(`${HOST_URL}${API_V1}/reports/story/solve_average_time`)
    .then(response => {
      console.log(response)
      this.setSolveAverage(response.data)
    }).catch(error=> {
      console.log(error)
    })

    // axios.get(`${HOST_URL}${API_V1}/reports/story/story_sell`)
    // .then(response => {
    //   this.setSell(response.data)
    // }).catch(error=> {
    //   console.log(error)
    // })

    axios.get(`${HOST_URL}${API_V1}/reports/story/retention_ratio`)
    .then(response => {
      this.setRetentionRatio(response.data)
    }).catch(error=> {
      console.log(error)
    })

  },
  data () {
    return {
      newStoryOpen: "",
      storiesOpen: { day: '', week: '', month: '' },
      solveRate: "",
      solveAverage: "",
      sell: "",
      compareNewStoryOpen: "",
      retention_ratio: 0,
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [ {
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    // setNewStoryOpen(data){
    //   this.newStoryOpen = {
    //     labels: data.labels,
    //     datasets: [
    //       {
    //         label: data.data_name,
    //         data: data.datasets,
    //         fill: false,
    //         borderColor: '#2554FF',
    //         backgroundColor: '#2554FF',
    //         borderWidth: 1
    //       }
    //     ]
    //   }
    // },
    setCompareNewStoryOpen(data){
      this.compareNewStoryOpen = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
            fill: false,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      }
    },
    setStoryOpen(data){
      this.storyOpen = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    },
    setSolveRate(data){
      this.solveRate = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    },
    setSolveAverage(data){
      this.solveAverage = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    },
    setSell(data){
      this.sell = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    },
    setRetentionRatio(data){
      this.retention_ratio = data.retention_ratio
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
