<template>
  <div>
    <div v-for="(memberIncrease, key) in memberIncreases" :key="key">
      <bar-chart v-if="memberIncrease != ''" :bar-data="memberIncrease"/>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/reports/bar-chart.vue';
import axios from 'axios';
import { HOST_URL, API_V1 } from '@/config/api';


export default {
  components: {
    BarChart
  },
  mounted(){
    ['day', 'week', 'month', 'year'].forEach(period => {
      axios.get(`${HOST_URL}${API_V1}/reports/member/increase_size?period=${period}`)
           .then(response => { const data = response.data;
                               this.memberIncreases[period] = {
                                 labels: data.labels,
                                 datasets: [
                                   {
                                     label: data.data_name,
                                     data: data.datasets,
                                     pointBackgroundColor: 'white',
                                     borderWidth: 1,
                                     pointBorderColor: '#249EBF',
                                     backgroundColor: '#f87979',
                                   }
                                ]
                               } })
           .catch(error => console.log(error));
    })
  },
  data () {
    return {
      memberIncreases: { day: '', week: '', month: '', year: '' },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [ {
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
