import axios from 'axios';
import { HOST_URL, API_V4 } from '@/config/api';

export const update = (groupId, memberLineId) => (itemType, itemName, options = {}) => {
  axios.put(`${HOST_URL}${API_V4}/groups/${groupId || memberLineId}/group_story_ships/${memberLineId}`,
            { item_type: itemType, item_name: itemName, ...options })
}

export const show = async (groupId, memberLineId) => {
  return axios.get(`${HOST_URL}${API_V4}/groups/${groupId || memberLineId}/group_story_ships/${memberLineId}`)
}
