/**
 * API configuration
 * This file centralizes the host URL for easier management
 */

// API host URL
export const HOST_URL = 'https://backend.judicial-intern.club';

// API paths
export const API_V1 = '/api/v1';
export const API_V2 = '/api/v2';
export const API_V3 = '/api/v3';
export const API_V4 = '/api/v4';

// Pages URLs
export const PAGES_URL = `${HOST_URL}/pages`;

// Specific API endpoints
export const ENDPOINTS = {
  // Members
  PROFILE: `${HOST_URL}${API_V1}/line/profile`,
  GET_TOOL_NUM: `${HOST_URL}${API_V1}/line/get_tool_num`,
  USE_TOOL: `${HOST_URL}${API_V1}/line/use_tool`,
  
  // Stories
  STORIES: `${HOST_URL}${API_V4}/stories`,
  STORY_TAGS_URL: `${HOST_URL}${API_V4}/story_tags`,
  
  // Groups
  GROUPS: `${HOST_URL}${API_V2}/groups`,
  GROUP_EXHIBITS: (groupId, lineId) => `${HOST_URL}${API_V2}/groups/${groupId || lineId}/exhibits?line_id=${lineId}`,
  GROUP_POSITIONS: (groupId, lineId) => `${HOST_URL}${API_V2}/groups/${groupId || lineId}/positions?line_id=${lineId}`,
  GROUP_RELATEDS: (groupId, lineId) => `${HOST_URL}${API_V2}/groups/${groupId || lineId}/relateds`,
  GROUP_TIMELINES: (groupId, lineId) => `${HOST_URL}${API_V2}/groups/${groupId || lineId}/timelines?line_id=${lineId}`,
  GROUP_STORY_SHIPS: (groupId, storyId, memberId) => `${HOST_URL}${API_V2}/groups/${groupId}/group_story_ships?storyId=${storyId}&memberId=${memberId}`,
  
  // Tutorial tasks
  TUTORIAL_TASKS: (taskName) => `${HOST_URL}${API_V3}/tutorial_tasks/${taskName}`,
  
  // Other
  AUTHORS: `${HOST_URL}${API_V4}/authors`,
  AD_INTERACTIONS_URL: `${HOST_URL}${API_V4}/ad_interactions`,
  PRODUCTS: `${HOST_URL}${API_V4}/products`,
  TRANSACTIONS: `${HOST_URL}${API_V4}/transactions`,
  PROFILES: `${HOST_URL}${API_V4}/profiles`,
  UNLOCK_EXHIBITS: (storyId) => `${HOST_URL}${API_V3}/stories/${storyId}/unlock_exhibits`,
  MEMBER_INFO: (lineId) => `${HOST_URL}${API_V2}/member_infos/${lineId}`,
  MEMBER_PROFILE: (lineId, groupId) => `${HOST_URL}${API_V4}/profiles/${lineId}?&groupId=${groupId}`,
  MEMBER_TOKENS: (lineId) => `${HOST_URL}${API_V2}/members/${lineId}/tokens`,
  GROUP_STORY_SHIP: `${HOST_URL}${API_V4}/groups/:groupId/group_story_ships/:lineId`,
  AD_INTERACTIONS_BY_ID: (id) => `${HOST_URL}${API_V4}/ad_interactions/${id}`
};

// Helper function to construct URLs with parameters
export const buildUrl = (baseUrl, params = {}) => {
  const url = new URL(baseUrl);
  Object.keys(params).forEach(key => {
    if (params[key] !== undefined && params[key] !== null) {
      url.searchParams.append(key, params[key]);
    }
  });
  return url.toString();
};

export default {
  HOST_URL,
  API_V1,
  API_V2,
  API_V3,
  API_V4,
  PAGES_URL,
  ENDPOINTS,
  buildUrl
};
