<template>
  <div class="discovery-list">
    <div class="discovery-types">
      <div 
        v-for="type in discoveryTypes" 
        :key="type.value"
        class="type-button"
        :class="{ active: selectedType === type.value }"
        @click="selectedType = type.value"
      >
        <img v-if="type.icon" :src="type.icon" class="type-icon" />
        {{ type.label }}
      </div>
    </div>
    
    <div class="discoveries">
      <div v-if="filteredDiscoveries.length === 0" class="no-records">
        尚無相關記錄
      </div>
      <div 
        v-for="(discovery, index) in filteredDiscoveries" 
        :key="index"
        class="discovery-item"
        :class="{ 
          'missing': discovery.discoverable_missing && discovery.discoverable_type !== 'Timeline',
          'failed': discovery.discoverable_missing && discovery.discoverable_type === 'Timeline'
        }"
      >
        <div class="discovery-header">
          <div class="discovery-type-info">
            <img :src="getTypeIcon(discovery.discoverable_type)" class="discovery-type-icon" />
            <span class="discovery-type-label">{{ getTypeLabel(discovery.discoverable_type) }}</span>
          </div>
          <div class="discovery-member">
            {{ discovery.discoverable_missing ? '搜索者' : '發現者' }}: {{ discovery.member_name }}
          </div>
        </div>
        <div class="discovery-name">
          <div v-if="discovery.discoverable_missing" class="status-badge" 
               :class="{ 'failed-badge': discovery.discoverable_type === 'Timeline' }">
            {{ discovery.discoverable_type === 'Timeline' ? '失敗' : '未找到' }}
          </div>
          <div class="name-text">{{ discovery.discoverable_name }}</div>
        </div>
        
        <div v-if="discovery.discoverable_missing" class="missing-message">
          {{ discovery.discoverable_type === 'Timeline' ? 
            '此時間線推理失敗，無法建立完整的事件順序。' : 
            '此項目已被搜尋但未能找到，可能需要更多線索或是已被移除。' }}
        </div>
        
        <div v-if="discovery.unlockables && discovery.unlockables.length > 0" class="unlockables">
          <div class="unlockables-title">解鎖內容:</div>
          <div 
            v-for="(unlockable, uIndex) in discovery.unlockables" 
            :key="uIndex"
            class="unlockable-item"
          >
            <img :src="getTypeIcon(unlockable.itemable_type)" class="unlockable-icon" />
            <span class="unlockable-type">{{ getTypeLabel(unlockable.itemable_type) }}:</span>
            <span class="unlockable-name">{{ unlockable.itemable_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscoveryList',
  props: {
    discoveries: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedType: 'all',
      discoveryTypes: [
        { value: 'all', label: '全部' },
        { 
          value: 'Exhibit', 
          label: '證物',
          icon: require('@/assets/images/exhibit-icon.png')
        },
        { 
          value: 'Position', 
          label: '場景',
          icon: require('@/assets/images/soul-icon.png')
        },
        { 
          value: 'Related', 
          label: '相關人',
          icon: require('@/assets/images/related-icon.png')
        },
        { 
          value: 'Timeline', 
          label: '時間軸',
          icon: require('@/assets/images/time-icon.png')
        }
      ]
    }
  },
  computed: {
    filteredDiscoveries() {
      if (this.selectedType === 'all') {
        return this.discoveries;
      }
      return this.discoveries.filter(discovery => 
        discovery.discoverable_type === this.selectedType
      );
    }
  },
  methods: {
    getTypeLabel(type) {
      const typeMap = {
        'Exhibit': '證物',
        'Position': '場景',
        'Related': '相關人',
        'Timeline': '時間軸',
        'Testimony': '證詞'
      };
      return typeMap[type] || type;
    },
    getTypeIcon(type) {
      const iconMap = {
        'Exhibit': require('@/assets/images/exhibit-icon.png'),
        'Position': require('@/assets/images/soul-icon.png'),
        'Related': require('@/assets/images/related-icon.png'),
        'Timeline': require('@/assets/images/time-icon.png'),
        'Testimony': require('@/assets/images/related-icon.png') // Using time icon for testimony as fallback
      };
      return iconMap[type] || '';
    }
  }
}
</script>

<style lang="scss" scoped>
.discovery-list {
  padding: 8px 16px;
  
  .discovery-types {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    
    .type-button {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 16px;
      cursor: pointer;
      font-weight: 500;
      transition: all 0.3s ease;
      
      .type-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      
      &.active {
        background-color: #b6d765;
        color: #333;
      }
      
      &:hover {
        background-color: rgba(182, 215, 101, 0.7);
      }
    }
  }
  
  .discoveries {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 60vh;
    overflow-y: auto;
    padding: 16px;
    
    .no-records {
      text-align: center;
      padding: 30px;
      font-size: 16px;
      color: #666;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 8px;
    }
    
    .discovery-item {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      padding: 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      
      &.missing {
        background-color: rgba(255, 200, 200, 0.8);
        border-left: 4px solid #ff6b6b;
      }
      
      &.failed {
        background-color: rgba(255, 180, 180, 0.8);
        border-left: 4px solid #ff4040;
      }
      
      .discovery-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        
        .discovery-type-info {
          display: flex;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.05);
          padding: 4px 10px;
          border-radius: 15px;
          
          .discovery-type-icon {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          
          .discovery-type-label {
            font-size: 14px;
            color: #555;
          }
        }
        
        .discovery-member {
          font-size: 14px;
          color: #666;
          text-align: right;
        }
      }
      
      .discovery-name {
        text-align: left;
        margin-bottom: 10px;
        
        .status-badge {
          display: inline-block;
          font-size: 12px;
          font-weight: normal;
          background-color: #ff6b6b;
          color: white;
          padding: 2px 8px;
          border-radius: 10px;
          margin-bottom: 5px;
          
          &.failed-badge {
            background-color: #ff4040;
          }
        }
        
        .name-text {
          font-size: 18px;
          font-weight: 600;
          word-break: break-word;
        }
      }
      
      .missing-message {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 10px;
        border-radius: 6px;
        font-size: 14px;
        color: #d32f2f;
        margin-bottom: 10px;
        font-style: italic;
      }
      
      .unlockables {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px dashed #ddd;
        
        .unlockables-title {
          font-weight: 500;
          margin-bottom: 5px;
          font-size: 14px;
          color: #666;
        }
        
        .unlockable-item {
          padding: 5px 0;
          display: flex;
          align-items: center;
          
          .unlockable-icon {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
          
          .unlockable-type {
            color: #666;
            margin-right: 5px;
          }
          
          .unlockable-name {
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
