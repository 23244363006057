<template>
  <div>
    <div id="receiceCake">
      <line-chart v-if="receiceCake != ''" :line-data="receiceCake" :options="options"/>
    </div>
    <br>
    <br>
    <br>
    <div id="cakeUse">
      <bar-chart v-if="cakeUse != ''" :bar-data="cakeUse"/>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/reports/line-chart.vue';
import BarChart from '@/components/reports/bar-chart.vue';
import axios from 'axios';
import { HOST_URL, API_V1 } from '@/config/api';


export default {
  components: {
    LineChart,
    BarChart
  },
  mounted(){
    axios.get(`${HOST_URL}${API_V1}/reports/cake/receive_cake_num`)
    .then(response => {
      this.setReceiveCake(response.data)
    }).catch(error=> {
      console.log(error)
    })

    axios.get(`${HOST_URL}${API_V1}/reports/cake/cake_used`)
    .then(response => {
      this.setCakeUse(response.data)
    }).catch(error=> {
      console.log(error)
    })
  },
  data () {
    return {
      receiceCake: "",
      cakeUse: "",
      chartData: {
        labels: ["Babol",	"Cabanatuan",	"Daegu",	"Jerusalem",	"Fairfield",	"New York",	"Gangtok", "Buenos Aires", "Hafar Al-Batin", "Idlib"],
        datasets: [
          {
            label: 'Line Chart',
            data: [600,	1150,	342,	6050,	2522,	3241,	1259,	157,	1545, 9841],
            fill: false,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [ {
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    setReceiveCake(data){
      this.receiceCake = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
            fill: false,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      }
    },
    setCakeUse(data){
      this.cakeUse = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
