var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'tutorial-mode': _vm.tutorialMode },attrs:{"id":"exhibit-2"}},[_c('BaseBanner',_vm._b({on:{"onLoaded":_vm.onLoaded}},'BaseBanner',_vm.bannerProps,false)),_c('main',{staticClass:"content"},[_c('section',{staticClass:"content"},[_c('div',{staticClass:"search-block",class:{ show: _vm.currentTutorialStep === 'searchExhibit' },style:({ 'background-image': ("url(" + (_vm.background.mainBg) + ")") })},[_c('Search',{attrs:{"customPlaceholder":"證物搜尋","searchText":"證物搜尋"},on:{"search-query":_vm.searchExhibit}}),_c('div',{staticClass:"tutorial tutorial-search-exhibit",class:[['searchExhibit'].includes(_vm.currentTutorialStep) ? '' : 'hide-tutorial']},[_c('p',[_vm._v("輸入證物名稱：「彩色DIY手環」，並按下搜尋按鈕")]),_c('div',{staticClass:"arrow-down"})])],1),_c('div',{staticClass:"process",class:{ show: _vm.currentTutorialStep === 'showExhibitSize' },style:({ 'background-image': ("url(" + (_vm.background.mainBg) + ")") })},[_c('img',{attrs:{"src":_vm.icon.search}}),_c('span',[_c('b',[_vm._v(_vm._s(_vm.foundTotalExhibitNum))]),_vm._v("/"+_vm._s(_vm.totalExhibitNum))]),_c('b-button',{attrs:{"type":"is-info is-light"},on:{"click":_vm.sharedOwnExhibits}},[_vm._v("分享證物")]),_c('div',{staticClass:"tutorial show-exhibit-size",class:[['showExhibitSize'].includes(_vm.currentTutorialStep) ? '' : 'hide-tutorial']},[_c('p',[_vm._v("目前已找到 3 個證物，總共有 8 個，還缺 5 個，這次判官魅嵐好心的幫你將可疑的證物加粗體字，試著找到全部的證物就能破案了！ "),_c('br'),_c('a',{on:{"click":_vm.nextTutorialStep}},[_vm._v("開始調查")])]),_c('div',{staticClass:"arrow-down"})])],1),_c('div',{staticClass:"exhibit-list",class:{ reverse: _vm.selectExhibitCate === 'found',
                     show: ['foundFirstExhibit', 'showFirstFoundExhibitDetail'].includes(_vm.currentTutorialStep) },style:({ 'background-image': ("url(" + (_vm.background.mainBg) + ")") })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.defaultExhibits.length > 0),expression:"defaultExhibits.length > 0"}],staticClass:"cate__content default",style:(_vm.computedDefaultStyles)},[_c('ul',{attrs:{"id":"default-exhibit-list"}},_vm._l((_vm.defaultExhibits),function(exhibit){return _c('li',{key:exhibit.tag,class:{ active: exhibit.tag === _vm.selectExhibit.tag },style:({
                  'background-image': ("url(" + (exhibit.tag === _vm.selectExhibit.tag ? _vm.icon.exhibitDefaultActive : _vm.icon.exhibitDefault) + ")")
                })},[_c('a',{on:{"click":function($event){return _vm.setSelectExhibit('default', exhibit.tag)}}},[_vm._v(" "+_vm._s(exhibit.name)+" ")])])}),0),_c('div',{staticClass:"detail",class:{
                 'not-active': _vm.selectExhibitCate !== 'default',
               },style:({ 'background-image': ("url(" + (_vm.icon.exhibitDefaultBG) + ")") })},[(_vm.found)?_c('p',{staticClass:"detail__text",domProps:{"innerHTML":_vm._s(_vm.changeLine(_vm.selectExhibit.report))}}):_c('p',{staticClass:"detail__text"},[_vm._v("查無此物")]),(_vm.totalExhibitNum != _vm.foundTotalExhibitNum)?_c('a',{staticClass:"tool",on:{"click":function($event){return _vm.userTool()}}},[_c('img',{attrs:{"src":_vm.icon.tool}}),_c('p',[_vm._v("使用蛋糕")])]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.foundExhibits.length > 0),expression:"foundExhibits.length > 0"}],staticClass:"cate__content found",style:(_vm.computedFoundStyles)},[_c('ul',{attrs:{"id":"found-exhibit-list"}},_vm._l((_vm.foundExhibits),function(exhibit){return _c('li',{key:exhibit.tag,class:{ active: exhibit.tag === _vm.selectExhibit.tag },style:({
                  'background-image': ("url(" + (exhibit.tag === _vm.selectExhibit.tag ? _vm.icon.exhibitFoundActive : _vm.icon.exhibitFound) + ")"),
                })},[_c('a',{on:{"click":function($event){return _vm.setSelectExhibit('found', exhibit.tag)}}},[_vm._v(" "+_vm._s(exhibit.name)+" ")])])}),0),_c('div',{staticClass:"detail",class:{ 'not-active': _vm.selectExhibitCate !== 'found',
                         show: ['showFirstFoundExhibitDetail'].includes(_vm.currentTutorialStep) },style:({ 'background-image': ("url(" + (_vm.icon.exhibitFoundBg) + ")") })},[(_vm.found)?_c('p',{staticClass:"detail__text",domProps:{"innerHTML":_vm._s(_vm.changeLine(_vm.selectExhibit.report))}}):_c('p',{staticClass:"detail__text"},[_vm._v("查無此物")]),(_vm.totalExhibitNum != _vm.foundTotalExhibitNum)?_c('a',{staticClass:"tool",on:{"click":function($event){return _vm.userTool()}}},[_c('img',{attrs:{"src":_vm.icon.tool}}),_c('p',[_vm._v("使用蛋糕")])]):_vm._e()])]),_c('div',{staticClass:"tutorial found-first-exhibit",class:[['foundFirstExhibit'].includes(_vm.currentTutorialStep) ? '' : 'hide-tutorial']},[_c('p',[_vm._v("找到證物「彩色DIY手環」"),_c('br'),_c('a',{on:{"click":_vm.nextTutorialStep}},[_vm._v("下一步")])]),_c('div',{staticClass:"arrow-down"})]),_c('div',{staticClass:"tutorial show-first-found-exhibit-detail",class:[['showFirstFoundExhibitDetail'].includes(_vm.currentTutorialStep) ? '' : 'hide-tutorial']},[_c('p',[_vm._v("此為證物「彩色DIY手環」相關描述"),_c('br'),_c('a',{on:{"click":_vm.nextTutorialStep}},[_vm._v("下一步")])]),_c('div',{staticClass:"arrow-down"})])])])]),_c('ToolPopup',{attrs:{"tool-num":_vm.toolNum,"tool-icon":_vm.icon.tool,"group-id":_vm.groupId},on:{"accept":_vm.accept},model:{value:(_vm.useToolPopup),callback:function ($$v) {_vm.useToolPopup=$$v},expression:"useToolPopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }