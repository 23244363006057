<template>
  <b-modal v-model="isOpen" @close="onClose">
    <div class="tool-modal">
      <img :src="toolIcon" /> <br />
      <h4>蛋糕</h4>
      <span>剩餘數量：{{ toolNum }}</span>
      <p v-if="toolNum != 0">確定把<strong>蛋糕</strong>交給察微判官魅嵐？</p>
      <p v-else>Oops! <strong>蛋糕</strong>好像用完了 🥺</p>
      <div class="modal__footer">
        <a class="cancel" @click.prevent="onClose">
          再想想
        </a>
        <a v-if="toolNum != 0" class="accept" @click.prevent="onAccept">
          交給她
        </a>
        <a v-else
           class="accept"
           :href="'https://liff.line.me/1653951718-o9GNrmEd?group_id=' + groupId">
          現在買
        </a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ToolPopup',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toolNum: {
      type: Number,
      default: 0
    },
    toolIcon: {
      type: String,
      required: true
    },
    groupId: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    onClose() {
      this.isOpen = false;
    },
    onAccept() {
      this.$emit('accept');
    }
  }
}
</script>

<style lang="scss" scoped>
.tool-modal {
  background-color: #fff;
  padding-top: 2rem;
  text-align: center;
  color: #333333;
  
  img {
    width: 72px;
    padding-bottom: 30px;
  }
  
  h4 {
    font-size: 20px;
    font-weight: 600;
  }
  
  span {
    font-size: 14px;
  }
  
  p {
    padding-top: 2rem;
    padding-bottom: 3rem;
    margin: 0;
    
    strong {
      color: #ff0000;
    }
  }
  
  .modal__footer {
    display: flex;
    
    a {
      flex: 1;
      font-size: 24px;
      color: #333333;
      line-height: 60px;

      &.cancel {
        background-color: #b1b1b1;
      }
      
      &.accept {
        background-color: #b6d765;
      }
    }
  }
}
</style>
